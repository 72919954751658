<template>
  <div>
    <my-nav-bar
      title="付款明细"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list
        v-model="loading"
        :finished="finished"
        @load="getList"
      >
        <van-cell
          v-for="iouPayment in list"
          :key="iouPayment.id"
          :title="'付款日期：' + iouPayment.payment_date"
          :value="'￥' + iouPayment.payment_amount"
          value-class="cell-value"
          title-class="cell-title"
        >
          <template #label>
            <div>
              <span>操作员：{{ iouPayment.employee_name }}</span>
              <span style="margin-left: 10px">付款方式：{{ iouPayment.payment_mode | paymentModeFilter }}</span>
            </div>
          </template>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getSupplierIouPayment } from '@/api/supplier-unpaid'
const paymentModeMaps = {
  1: '现金',
  2: '微信',
  3: '转账'
}
export default {
  components: { myNavBar },
  filters: {
    paymentModeFilter(paymentMode) {
      return paymentModeMaps[paymentMode]
    }
  },
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      listQuery: {
        page: 0,
        limit: 20,
        iou_id: this.$route.query.iou_id
      },
      showEmpty: false
    }
  },
  methods: {
    getList() {
      this.listQuery.page++
      getSupplierIouPayment(this.listQuery).then(res => {
        this.loading = false
        this.list = this.list.concat(res.data.list)
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/color.scss';
  .cell-value {
    color: $amountRedColor;
  }
  .cell-title {
    flex: 2;
  }
</style>
